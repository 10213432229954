<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="医生列表"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <h2 class="van_title">我的医生列表</h2>
        <div v-if="active>0&&!user_empty">
            <cell-group  v-for="(idx,index) in doctors"  :key="index">
                <cell :title="idx.name" :label="idx.gender!=null?idx.gender:''+'  '+idx.department!=null?idx.department:''"
                      icon="manager-o" @click="toC2C(idx.id)" > <!-- @click="showQr(idx.ticket,idx.name)"-->
                    <template #right-icon>
                        <Icon name="chat-o" class="search-icon" color="#1989fa" size="40" style="padding-right: 20px"/>
                    </template>
                </cell>
            </cell-group>
        </div>
        <h2 class="van_title" v-if="groupSize>0">我的群组列表</h2>
        <div v-if="groupSize>0&&!user_empty">
            <cell-group  v-for="(idx,index) in groups"  :key="index">
                <cell :title="idx.name"
                      icon="manager-o" @click="toGROUP(idx.groupId)" ><!--@click="showQr(idx.ticket,idx.name)"-->
                    <template #right-icon>
                        <Icon name="chat-o" class="search-icon" color="#1989fa" size="40" style="padding-right: 20px"/>
                    </template>
                </cell>
            </cell-group>
        </div>

        <Popup v-model="show">
            <vimg :src="qrUrl"> </vimg>
            <p style="text-align: center;">{{ qrName }}</p>
            <p style="text-align: center;">长按识别二维码与医生沟通或分享给朋友</p>
        </Popup>

        <!-- <a ref="miniProgramLink" :href="miniProgramLink" style="display: none;" /> -->
        <empty v-if="nodata&&!user_empty" description="查无数据" />
        <Loading type="spinner" color="#1989fa" size="24px" vertical v-if="user_empty" style="margin-top: 40%">获取授权中...</Loading>

        <RLFooter/>
    </div>
</template>

<script>

    import {Cell,Empty,CellGroup,Loading,Icon,Image as vimg ,Popup} from 'vant';
    import {getDoctors,getMpUrlLink} from '@/api/doctor/baseinfo';
    // import {getBaseInfo} from '@/api/patient/baseinfo';
    import {setToken, removeToken, getToken} from '@/utils/auth';
    import {getUser} from '@/api/user';

    export default {
        name: 'QueIdx',
        components: {
            Cell,Empty,CellGroup,Loading,Icon,vimg,Popup
        },
        data(){
            return {
                miniProgramLink:'',
                active:0,
                doctors:[],
                followcrfs:[],
                nodata:true,
                user:{
                    userName:'',
                    nickName:'',
                    idCard:'',
                    phone:'',
                    gender:'1',
                    birthday:'',
                    id:'',
                    pid:''
                },
                user_empty:true,
                follow_time:null,
                show:false,
                url:'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=',
                qrUrl:'',
                qrName:'',
                groupSize:0,
                groups:[],
            };
        },
        computed:{

        },
        methods:{
            toGROUP(gid){
                let that=this;
                getMpUrlLink({timid:"GROUP"+gid}).then(res=>{
                   if(res.code==200 ){
                        console.info(res.data);
                        that.miniProgramLink = res.data.replace(/"/g, "");
                        console.info(that.miniProgramLink);
                        window.location.href = that.miniProgramLink;
                   }else{
                      that.$toast.fail(res.msg);
                   }
                })
            },
            toC2C(uid){
                let that=this;
                getMpUrlLink({timid:"C2Cdoctor_"+uid}).then(res=>{
                   if(res.code==200 ){
                        console.info(res.data);
                        that.miniProgramLink = res.data.replace(/"/g, "");
                        console.info(that.miniProgramLink);
                        window.location.href = that.miniProgramLink;
                   }else{
                      that.$toast.fail(res.msg);
                   }
                })
            },
            showQr(ticket,name){
                console.log(ticket)
                this.qrUrl = this.url+ticket
                this.qrName = name
                this.show =true
            },
            showCrf(idx){
                // this.$router.push({path: '/followcrf/'+idx.id});
                this.$router.push({path: '/followcrf/',query:{crfname:idx.send_id,sendid:idx.id,itemdataorderid:idx.itemdataorderid,follow_time:this.follow_time}});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },
            initData(){
                // let follow_time=this.parseTime(new Date(),'{y}-{m}-{d}');
                getDoctors({patientid:this.user.id}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        // this.loading=true;
                        this.active=response.data.data.doctors.length;

                        this.doctors=response.data.data.doctors;
                        console.log(response.data)

                        this.groupSize=response.data.data.groups.length;

                        this.groups=response.data.data.groups;

                        if(this.doctors.length==0 && this.groups.length == 0){
                            this.nodata = true
                        }else{
                            this.nodata = false
                        }
                    }
                });
            },
        }
        ,
        created() {
          // 开发环境
          if(process.env.NODE_ENV=='development'){
            getUser().then((res) => {
              console.info(res.data);

              if(res.data){
                this.$store.commit('updateOpenid',res.data.openid);
                this.$store.commit('updatePid',res.data.id);
                this.$store.commit('updateBaseInfo',res.data);
                if(res.data.name !=''){
                  this.substatus = true
                }
                this.user.id=res.data.id;
                this.user.name=res.data.name;
                this.user.birthday=res.data.birthday;
                // this.user.idCard=response.data.idCard;
                this.user.telephone=res.data.telephone;
                this.user.gender=res.data.gender+'';
                this.user.address=res.data.address;
                this.user.detail_address=res.data.detail_address;
                // this.initBaseInfo();
                this.user_empty = false

                this.initData();

              }
            });
          }else{
            //生产环境
            var query = this.$route.query;
            let codeTemp = getToken();

            if(query.code || this.getQueryString('code') || codeTemp){
              // console.log("code")
              // console.log(query.code)
              // console.log(this.getQueryString('code'))
              //this.$store.commit('updateOpenid','123456');
              if(query.code || this.getQueryString('code')){
                this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
                setToken(query.code?query.code : this.getQueryString('code'));
              }
              getUser().then((res) => {
                console.info(res.data);

                if(res.data){
                  this.$store.commit('updateOpenid',res.data.openid);
                  this.$store.commit('updatePid',res.data.id);
                  this.$store.commit('updateBaseInfo',res.data);
                  if(res.data.name !=''){
                    this.substatus = true
                  }
                  this.user.id=res.data.id;
                  this.user.name=res.data.name;
                  this.user.birthday=res.data.birthday;
                  // this.user.idCard=response.data.idCard;
                  this.user.telephone=res.data.telephone;
                  this.user.gender=res.data.gender+'';
                  this.user.address=res.data.address;
                  this.user.detail_address=res.data.detail_address;
                  // this.initBaseInfo();
                  this.user_empty = false

                  this.initData();

                }
              });
              //getBaseInfo({}).then((response) => {
              //   console.info(response);
              // });
            }else{
              removeToken();
            }
          }

            // this.initBaseInfo();
            /*初始化列表*/
            // this.initidx();

        },
    }
</script>

<style scoped>

    .is_completed{
        color: #19ff18;
        /*color: aqua;*/
    }
    .is_uncompleted{
        color:red;
    }


</style>
